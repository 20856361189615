<template>
  <div class="qa-container-info d-inline-flex flex-column">
    <span class="qa-primary-info" :class="[headlineType]">
      {{ primaryInfo }}
      <span v-if="posInfo">{{ posInfo }}</span>
    </span>

    <div class="qa-secondary-info">
      <slot></slot>
    </div>

    <div>
      <a v-if="link" :href="link" target="__blank" class="qa-link-info">
        {{ linkLabel }}
      </a>
      <b class="text--secondary" v-else>{{ linkLabel }}</b>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    primaryInfo: {
      type: [String, Number],
      required: true,
    },
    posInfo: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      default: '',
    },
    linkLabel: {
      type: String,
      default: '',
    },
    highlight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    headlineType() {
      return this.highlight
        ? 'text-h5 font-weight-bold'
        : 'text-h6 font-weight-bold'
    },
  },
}
</script>
