<template>
  <v-card class="qa-card-container mx-auto my-4" width="350">
    <v-card-title class="qa-card-title pb-0">{{ title }}</v-card-title>
    <v-card-text class="pb-0">
      <v-row>
        <v-col>
          <slot name="column1"></slot>
        </v-col>
        <v-col>
          <slot name="column2"></slot>
        </v-col>
      </v-row>
    </v-card-text>

    <div class="qa-card-progress-label pl-4 pb-2">{{ progressLabel }}</div>

    <v-progress-linear :value="progress" color="green accent-4" height="30">
      <strong class="qa-card-progress-percentage"> {{ progress }}% </strong>
    </v-progress-linear>
  </v-card>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    progressLabel: { type: String, required: true },
    progress: { type: Number, required: true },
  },
}
</script>
