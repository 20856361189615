<template>
  <div>
    <permission-failed-message v-if="!hasPermission($p.harvest.list)" />
    <farm-disabled v-else-if="!farms.length" />
    <div v-else>
      <v-col cols="12">
        <crop-tabs class="pt-3" :disable-crops="['Wheat']" />
        <v-row no-gutters class="pt-3">
          <v-row class="mb-6" no-gutters>
            <v-col md="4" class="title-screen-performance">
              {{ $t('menu.performance') }}
            </v-col>
            <v-col offset-md="4">
              <selector-seasons-crops
                @changed="prepareStats"
              ></selector-seasons-crops>
            </v-col>
          </v-row>
          <v-col cols="12">
            <panel>
              <template>
                <div slot="content" v-if="talhoes.length">
                  <info-header :info="totalInfo"></info-header>
                  <tabs :items="talhoes"></tabs>
                </div>
                <div slot="content" v-else>
                  <v-alert
                    icon="mdi-information-outline"
                    prominent
                    text
                    type="info"
                  >
                    {{ $t('Performance.Messages.withoutHarvests') }}
                  </v-alert>
                </div>
              </template>
            </panel>
          </v-col>
        </v-row>
      </v-col>
    </div>
  </div>
</template>

<script>
import CropTabs from '@/components/Crops/CropTabs.vue'
import FarmDisabled from '@/components/Banners/FarmDisabled.vue'
import InfoHeader from '@/modules/performance/components/InfoHeader.vue'
import PerformanceService from '@/modules/performance/services/PerformanceService.js'
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import Panel from '@/components/Panel'
import SelectorSeasonsCrops from '@/components/SeasonsCrops/SelectorSeasonsCrops.vue'
import Tabs from '@/modules/performance/components/Tabs.vue'
import { mapGetters } from 'vuex'
import { logEvent, events } from '@/services/analytics'

export default {
  name: 'PerformanceHome',

  mixins: [PermissionMixin],

  components: {
    CropTabs,
    FarmDisabled,
    InfoHeader,
    Panel,
    SelectorSeasonsCrops,
    Tabs,
  },
  data() {
    return {
      performanceService: new PerformanceService(this.$api),
      totalInfo: {
        avgSacksSown: 0,
        totalSacksSown: 0,
        totalSeeding: 0,
        totalApplications: 0,
        cultivarTotals: 0,
      },
      talhoes: [],
    }
  },

  watch: {
    currentFarmId: {
      async handler() {
        await this.prepareStats()
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters('farms', ['farms', 'currentFarm', 'currentFarmId']),
    ...mapGetters('seasonsCrops', ['currentSeasonCrop']),
  },

  mounted() {
    if (
      this.$toggle('tour_enabled') &&
      !localStorage.getItem('tour-selector')
    ) {
      this.$tours['tourUnifiedFarm']?.start()
    }

    this.$root.$emit('breadcrumbsMenuPrincipal')
    logEvent(events.performanceModule.visitedPage)
  },

  methods: {
    async prepareStats() {
      this.performanceService
        .fetchStats(
          this.currentFarmId,
          this.currentSeasonCrop?.start_period,
          this.currentSeasonCrop?.end_period,
          this.totalInfo,
          this.talhoes
        )
        .then((result) => {
          if (result) {
            this.totalInfo = result.totalInfo
            this.talhoes = result.talhoes
          } else {
            this.totalInfo = null
            this.talhoes = []
          }
        })
    },
  },
}
</script>

<style scoped>
.title-screen-performance {
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #071505;
  padding-top: 10px;
}
</style>
