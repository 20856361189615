<!-- eslint-disable vue/no-unused-properties -->
<script>
import { PLOTS_TYPES } from '@/modules/performance/data/configs'
import ColheitaCard from './ColheitaCard'

const cardFactory = {
  [PLOTS_TYPES.COLHEITA]: ColheitaCard,
}

export default {
  name: 'CardChooser',
  props: {
    type: { type: String, required: true },
    title: { type: String, required: true },
    progress: { type: Number, required: true },
    info: { type: Object, required: true },
  },
  render(createElement) {
    const card = cardFactory[this.type]

    return createElement(card, {
      props: {
        ...this.$props,
      },
    })
  },
}
</script>
