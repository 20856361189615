<template>
  <container-info-card
    :title="title"
    :progress="progress"
    :progress-label="`${$t('colheita.colheita')} (%)`"
  >
    <template #column1>
      <span class="text-1 font-weight-black">
        {{ averageProductivity }}
        <span>{{ $unitMeasures['performance'][$currentLanguage()] }}</span>
      </span>
      <div>{{ $t('Performance.Productivity.average') }}</div>

      <span class="text-2 font-weight-black mt-2">
        {{ totalProductivity }}
        <span>{{ $unitMeasures['weight'][$currentLanguage()] }}</span>
      </span>
      <div>{{ $t('Performance.Productivity.total') }}</div>
    </template>
    <template #column2>
      <div>{{ $t('Performance.Plot.products_cost') }}:</div>
      <div class="font-weight-black">
        {{ $currency(info.custo) }}
        <span>/ {{ $unitMeasures['area'][$currentLanguage()] }}</span>
      </div>
      <div>
        {{ $t('Performance.Plot.sprays') }}:
        <span class="font-weight-black">{{ info.aplicacoes }}</span> /
        {{ info.maxAplicacoes }}
      </div>
      <div>
        {{ $t('Performance.Plot.medium_cycle') }}:
        <span>
          {{ info.cicloMedio }}
          {{ $tc('qt_dias', info.cicloMedio.length) }}
        </span>
      </div>
    </template>
  </container-info-card>
</template>

<script>
import { convertToTargetUnit } from '@/components/Form/converter'
import ContainerInfoCard from './ContainerInfoCard'

export default {
  components: { ContainerInfoCard },
  props: {
    title: { type: String, required: true },
    progress: { type: Number, required: true },
    info: { type: Object, required: true },
  },
  computed: {
    averageProductivity() {
      return convertToTargetUnit(this.info.produtividade, 'performance')
    },

    totalProductivity() {
      return convertToTargetUnit(this.info.produtividadeTotal, 'weight')
    },
  },
}
</script>
