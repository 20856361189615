import { PLOTS_TYPES } from '@/modules/performance/data/configs'
import $t from '@/modules/performance/translation'

export default class PerformanceService {
  constructor(api) {
    this.api = api
  }

  async fetchStats(currentFarmId, startPeriod, endPeriod, totalInfo, talhoes) {
    this.currentLanguage = localStorage.getItem('language')

    try {
      const { data } = await this.api.fazendas.stats(
        currentFarmId,
        startPeriod,
        endPeriod
      )

      if (!data?.talhoes?.length) {
        talhoes = []
        return
      }

      totalInfo = {
        avgSacksSown: data.media_total_sacas_produzidas_fazenda ?? 0,
        totalSacksSown: data.total_sacas_produzidas_fazenda ?? 0,
        maxProductivity: data.talhao_mais_produtivo,
        minProductivity: data.talhao_menos_produtivo,
        totalSeeding:
          `${data.total_semeaduras_fazenda} ` +
          $t[this.currentLanguage].Performance.Types.plantings,
        totalApplications:
          `${data.total_aplicacoes_fazenda} ` +
          $t[this.currentLanguage].Performance.Types.sprays,
        cultivarTotals:
          `${data.total_cultivares} ` +
          $t[this.currentLanguage].Performance.Types.varieties,
      }

      talhoes = data.talhoes
        .map((talhao) => ({
          tab: 0,
          id: talhao.talhao_id,
          type: PLOTS_TYPES.COLHEITA,
          title: talhao.talhao_nome,
          progress: parseFloat(talhao.porcentagem_colhida.toFixed(2)),
          info: {
            custo: Number(talhao?.preco_da_area ?? 0),
            produtividade: talhao.qnt_sc_media_semeada_talhao,
            produtividadeTotal: talhao.qnt_sc_total_semeada_talhao,
            aplicacoes: talhao.fungicidas_aplicados,
            maxAplicacoes: talhao.quantidade_total_aplicacoes,
            cicloMedio: talhao.ciclo_medio,
          },
        }))
        .sort(function (a, b) {
          return b.progress - a.progress
        })

      return { totalInfo, talhoes }
    } catch (error) {
      throw new Error('Failed to fetch stats')
    }
  }
}
