<template>
  <div>
    <v-tabs center-active centered>
      <v-tab v-for="tab in tabs" :key="tab.index">
        {{ $t(tab.labelKey) }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="currentTab">
      <div class="d-flex justify-space-around flex-wrap">
        <v-tab-item
          class="d-inline-flex"
          v-for="item in items"
          :key="item.id"
          :value="currentTab"
        >
          <card-chooser
            :type="item.type"
            :title="item.title"
            :progress="item.progress"
            :info="item.info"
          ></card-chooser>
        </v-tab-item>
      </div>
    </v-tabs-items>
  </div>
</template>

<script>
import CardChooser from '@/modules/performance/components/Cards/CardChooser'
import { TABS } from '@/modules/performance/data/configs'

export default {
  components: {
    CardChooser,
  },
  props: {
    items: { type: Array, required: true },
  },
  data() {
    return {
      tabs: TABS,
      currentTab: 0,
    }
  },
}
</script>
