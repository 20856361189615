<template>
  <v-row class="d-flex justify-center">
    <v-col md="2" cols="12" class="d-flex justify-md-center">
      <info-container
        :primary-info="averageProductivity"
        :pos-info="$unitMeasures['performance'][this.$currentLanguage()]"
        highlight
      >
        {{ $t('Performance.Productivity.average') }}
      </info-container>
    </v-col>
    <v-col md="2" cols="12" class="d-flex justify-md-center">
      <info-container
        :primary-info="maxProductivity"
        :pos-info="$unitMeasures['performance'][this.$currentLanguage()]"
        :link-label="info.maxProductivity.talhao"
      >
        {{ $t('Performance.Productivity.highest_average') }}
      </info-container>
    </v-col>
    <v-col md="2" cols="12" class="d-flex justify-md-center">
      <info-container
        :primary-info="minProductivity"
        :pos-info="$unitMeasures['performance'][this.$currentLanguage()]"
        :link-label="info.minProductivity.talhao"
      >
        {{ $t('Performance.Productivity.lowest_average') }}
      </info-container>
    </v-col>
    <v-col md="2" cols="12" class="d-flex justify-md-center">
      <info-container
        :primary-info="totalProductivity"
        :pos-info="$unitMeasures['weight'][$currentLanguage()]"
        highlight
      >
        <div>{{ info.totalSeeding }}</div>
        <div>{{ info.cultivarTotals }}</div>
        <div>{{ info.totalApplications }}</div>
      </info-container>
    </v-col>
  </v-row>
</template>

<script>
import { convertToTargetUnit } from '@/components/Form/converter'
import InfoContainer from './InfoContainer'

export default {
  components: {
    InfoContainer,
  },
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  computed: {
    averageProductivity() {
      return convertToTargetUnit(this.info.avgSacksSown, 'performance')
    },

    maxProductivity() {
      return convertToTargetUnit(
        this.info.maxProductivity.produtividade_media,
        'performance'
      )
    },

    minProductivity() {
      return convertToTargetUnit(
        this.info.minProductivity.produtividade_media,
        'performance'
      )
    },

    totalProductivity() {
      return convertToTargetUnit(this.info.totalSacksSown, 'weight')
    },
  },
}
</script>
